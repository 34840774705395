var minimumShowLoadingTimeReached = false;
    setTimeout(function() {minimumShowLoadingTimeReached = true;}, 0);

// Ждём загрузки всех картинок и скрываем preloader
function preloader() {
  if (minimumShowLoadingTimeReached) {
      $('.preloader').fadeOut();
      $('.first-time').removeClass('first-time');

  } else {
      setTimeout(function() {
        preloader();
      }, 500);
  }
}

window.onload = preloader;

$(document).ready(function(){
	var $btnMainMenu 	= $(".btn-main-menu"),
		$header 		= $('header'),
		$mainMenuLg 	= $('.main-menu-lg'),
		isMenuActive	= false,
		$langWrp 		= $('.lang-wrp'),
		$logoHeader 	= $('.logo-wrp.main');

	// Постраничная прокрутка
	$('main').fullpage({
		onLeave: function(index, nextIndex, direction){

			// Если меню открыто, то не меняем секцию
			if (isMenuActive){
				return false;
			}

			// На первой секции отображаем горизонтальное меню, на остальных бургер меню
			if(index == 1 && direction =='down'){
				if ($(window).width() > 1023){
					$btnMainMenu.fadeIn();
					$mainMenuLg.addClass('hidden');
				}
				$logoHeader.addClass('active');
			} else if(nextIndex == 1 && direction == 'up'){
				if ($(window).width() > 1023){
					$btnMainMenu.fadeOut();
					$mainMenuLg.removeClass('hidden');
				}
				$logoHeader.removeClass('active');
			}

			// Убираем лого на 4-ом слайде
			if(index == 3 && direction =='down'){
				$logoHeader.removeClass('active');
			}
			if(nextIndex == 4){
				$logoHeader.removeClass('active');
			}
			if(index == 4){
				$logoHeader.addClass('active');
			}

			// Убираем лого на слайде с картой
			if ($(window).width() > 1023){
				if(index == 6 && direction =='down'){
					$logoHeader.removeClass('active');
				}
				if(nextIndex == 6 && direction == 'up'){
					$logoHeader.addClass('active');
				}
			}

			// Убираем лого на 4-ом слайде
			if(index == 4 && direction =='down'){
				$header.addClass('grey');
			}
			if(nextIndex == 5){
				$header.addClass('grey');
			}
			if(index == 5){
				$header.removeClass('grey');
			}


		},
		afterLoad: function(origin, destination, direction){
			$.fn.fullpage.setScrollingSpeed(700);
			var $video = $('video');
		      if ($video.length && minimumShowLoadingTimeReached){
		      	$video[0].play();
		    }
		},
		onSlideLeave: function(anchorLink, index, slideIndex, direction, nextSlideIndex) {
			var $prev = $('.fp-controlArrow.fp-prev'),
				$next = $('.fp-controlArrow.fp-next');
			if (nextSlideIndex === $('.fp-section.active .fp-slide').length - 1) {
				$next.addClass('hidden')
			} else {
				$next.removeClass('hidden')
			}
			if (nextSlideIndex == 0){
				$prev.addClass('hidden')
			} else {
				$prev.removeClass('hidden')
			}
		},
		afterRender: function(){
			$('.fp-controlArrow.fp-prev').addClass('hidden');
		}
	});

	// Открытие выпадающего меню
	$btnMainMenu.on('click', function(){

		$header.toggleClass('opened');
		if ($(window).width() < 1280){
			$langWrp.toggle();
		}
		isMenuActive ? isMenuActive = false : isMenuActive = true;
	});

    // Переход по ссылкам
    var $links = $('[data-url]');
    $links.on('click', function(){
        
        if (isMenuActive){
            // Кликнули по выпадающему меню
            $btnMainMenu.trigger('click');
        }
        $.fn.fullpage.moveTo(parseInt($(this).data('url')));
    });

    // Открытие языковой панели
    $langWrp.on('click', '.link.active', function(){
        var $parent = $(this).parent();
        if ($parent.hasClass('opened')){
            $parent.removeClass('opened');
        } else {
            $parent.addClass('opened');
            // Ставим интервал
            // каждые 2 секунды проверяем, если кнопки языков не под ховером,
            // то сворачиваем панельку
            var langTimer = setInterval(function(){

                if(!$parent.is(':hover')) {
                    $parent.removeClass('opened');
                    clearInterval(langTimer);
                }

            }, 2000);
        }
    });

	// Карта
	var $map = $('#map'),
		myLatLng = {lat: $map.data('lat'), lng: $map.data('lng')};

	var map = new google.maps.Map(document.getElementById('map'), {
      center: myLatLng,
      scrollwheel: false,
      zoom: 10,
      backgroundColor:"#eeeeee"
    });

    var markerImage = new google.maps.MarkerImage(
        'img/marker.png',
        new google.maps.Size(18,18),
        new google.maps.Point(0,0),
        new google.maps.Point(0,33)
    );

    var marker = new google.maps.Marker({
      icon: markerImage,
      map: map,
      position: myLatLng,
      title: 'Alezar'
    });

    var style = [
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#e9e9e9"
            },
            {
                "lightness": 17
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#f5f5f5"
            },
            {
                "lightness": 20
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "lightness": 17
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "lightness": 29
            },
            {
                "weight": 0.2
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "lightness": 18
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "lightness": 16
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#f5f5f5"
            },
            {
                "lightness": 21
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#dedede"
            },
            {
                "lightness": 21
            }
        ]
    },
    {
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#ffffff"
            },
            {
                "lightness": 16
            }
        ]
    },
    {
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "saturation": 36
            },
            {
                "color": "#333333"
            },
            {
                "lightness": 40
            }
        ]
    },
    {
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#f2f2f2"
            },
            {
                "lightness": 19
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#fefefe"
            },
            {
                "lightness": 20
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#fefefe"
            },
            {
                "lightness": 17
            },
            {
                "weight": 1.2
            }
        ]
    }
];

	var mapType = new google.maps.StyledMapType(style, {name:"Grayscale"});
	map.mapTypes.set('grey', mapType);
	map.setMapTypeId('grey');

});